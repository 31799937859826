<template>
  <div class="home">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="购买 USDT" name="first"></el-tab-pane>
        <el-tab-pane label="出售 USDT" name="second"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="search">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item>
              <el-select v-model="formInline.type" placeholder="活动区域">
                <el-option label="购买 USDT" value="buy"></el-option>
                <el-option label="出售 USDT" value="sell"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formInline.number"
                placeholder="请输入欲交易的USDT"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button @click="onSubmit">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="table">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="user" label="广告发布用户">
          <template slot-scope="scope">
            <div class="user-index">{{ scope.row.user.slice(0, 1) }}</div>
            {{ scope.row.user }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价(CNY)"></el-table-column>
        <el-table-column prop="usdtLimit" label="单笔限额(USDT)">
        </el-table-column>
        <el-table-column prop="cnyLimit" label="单笔限额(CNY)">
        </el-table-column>
        <el-table-column prop="payment" label="支付方式">
          <template slot-scope="scope">
            <img
              v-if="scope.row.payment == 'alipay'"
              src="@/assets/alipay.png"
              width="20"
              height="20"
            />
            <img
              v-if="scope.row.payment == 'wechat'"
              src="@/assets/wechat.png"
              width="20"
              height="20"
            />
            <img
              v-if="scope.row.payment == 'unipay'"
              src="@/assets/unipay.png"
              width="20"
              height="20"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作(0手续费)">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >购买</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div class="page">
      <el-row>
        <el-col :span="18" :offset="3">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="15"
            background
          >
          </el-pagination>
        </el-col>
      </el-row>
    </div> -->
    <div class="tips">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
          ><div class="grid-content bg-purple">
            <p class="tip1">交易警示</p>
            <p class="tip2">WX严密监控和禁止任何以下行为:</p>
            <p class="tip3">
              -洗钱<br />
              -诈骗活动<br />
              -和其他任何违反我们当地法律法规的活动<br />
              如发现上述情况,WX将对违法者账号进行封禁并向相关执法部门举报。
            </p>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="footer">
      <FooterView></FooterView>
    </div>
  </div>
</template>

<script>
import FooterView from '@/components/FooterView.vue'
import axios from 'axios'
export default {
  name: 'HomeView',
  components: { FooterView },
  data() {
    return {
      loading: true,
      currentPage: 1,
      activeName: 'first',
      value: 'buy',
      input: '',
      formInline: {
        number: '0.00',
        type: 'buy',
      },
      tableData: [],
      tableDataBuy: [
        {
          user: '干*克',
          price: 7.35,
          usdtLimit: '298.00 - 298.00',
          cnyLimit: '2,190.30 - 2,190.30',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '申屠*朴',
          price: 7.36,
          usdtLimit: '500.00 - 4,000.00',
          cnyLimit: '3,680.00 - 29,440.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '萧*君',
          price: 7.36,
          usdtLimit: '800.00 - 5,000.00',
          cnyLimit: '5,888.00 - 36,800.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '姚*宁',
          price: 7.36,
          usdtLimit: '1,000.00 - 2,000.00',
          cnyLimit: '7,360.00 - 14,720.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '时*克',
          price: 7.36,
          usdtLimit: '300.00 - 1,290.00',
          cnyLimit: '2,208.00 - 9,494.40',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '饶*涌',
          price: 7.36,
          usdtLimit: '200.00 - 3,000.00',
          cnyLimit: '1,472.00 - 22,080.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '赖*涌',
          price: 7.36,
          usdtLimit: '700.00 - 1,300.00',
          cnyLimit: '5,173.00 - 9,607.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '卢*怀',
          price: 7.37,
          usdtLimit: '500.00 - 1,000.00',
          cnyLimit: '3,685.00 - 7,370.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '嵇*贤',
          price: 7.37,
          usdtLimit: '600.00 - 800.00',
          cnyLimit: '4,422.00 - 5,896.00',
          payment: 'unipay',
          action: 'alipay',
        },
        {
          user: '段干*业',
          price: 7.38,
          usdtLimit: '500.00 - 1,500.00',
          cnyLimit: '3,690.00 - 11,070.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '苗*炜',
          price: 7.38,
          usdtLimit: '260.00 - 6,500.00',
          cnyLimit: '1,918.80 - 47,970.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '司徒*翔',
          price: 7.38,
          usdtLimit: '1,200.00 - 3,000.00',
          cnyLimit: '8,856.00 - 22,140.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '楚*迎',
          price: 7.38,
          usdtLimit: '300.00 - 2,000.00',
          cnyLimit: '2,214.00 - 14,760.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '屈*旺',
          price: 7.38,
          usdtLimit: '200.00 - 3,000.00',
          cnyLimit: '1,476.00 - 22,140.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '邰*博',
          price: 7.39,
          usdtLimit: '1,000.00 - 3,333.00',
          cnyLimit: '7,390.00 - 24,630.87',
          payment: 'alipay',
          action: 'alipay',
        },
      ],
      tableDataSell: [
        {
          user: '冉*嵩',
          price: 7.29,
          usdtLimit: '300.00 - 5,000.00',
          cnyLimit: '2,187.00 - 36,450.00',
          payment: 'unipay',
          action: 'alipay',
        },
        {
          user: '袁*兴',
          price: 7.29,
          usdtLimit: '200.00 - 1,000.00',
          cnyLimit: '1,458.00 - 7,290.00',
          payment: 'unipay',
          action: 'alipay',
        },
        {
          user: '厍*登',
          price: 7.29,
          usdtLimit: '1,000.00 - 3,000.00',
          cnyLimit: '7,290.00 - 21,870.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '滑*平',
          price: 7.29,
          usdtLimit: '100.00 - 2,000.00',
          cnyLimit: '729.00 - 14,580.00',
          payment: 'unipay',
          action: 'alipay',
        },
        {
          user: '龙*浩',
          price: 7.28,
          usdtLimit: '100.00 - 5,000.00',
          cnyLimit: '728.00 - 36,400.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '姜*钟',
          price: 7.28,
          usdtLimit: '100.00 - 2,000.00',
          cnyLimit: '728.00 - 14,560.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '空*英',
          price: 7.27,
          usdtLimit: '100.00 - 2,000.00',
          cnyLimit: '727.00 - 14,540.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '黎*黎',
          price: 7.25,
          usdtLimit: '100.00 - 5,000.00',
          cnyLimit: '1,450.00 - 36,250.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '项*咏',
          price: 7.2,
          usdtLimit: '100.00 - 10,000.00',
          cnyLimit: '720.00 - 72,000.00',
          payment: 'unipay',
          action: 'alipay',
        },
        {
          user: '吕*桥',
          price: 7.15,
          usdtLimit: '100.00 - 50,000.00',
          cnyLimit: '715.00 - 357,500.00',
          payment: 'unipay',
          action: 'alipay',
        },
        {
          user: '司徒*锟',
          price: 7.15,
          usdtLimit: '100.00 - 10,000.00',
          cnyLimit: '715.00 - 71,500.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '利*权',
          price: 7.13,
          usdtLimit: '100.00 - 10,000.00',
          cnyLimit: '713.00 - 71,300.00',
          payment: 'wechat',
          action: 'alipay',
        },
        {
          user: '翟*显',
          price: 7.11,
          usdtLimit: '1,000.00 - 2,000.00',
          cnyLimit: '7,110.00 - 14,220.00',
          payment: 'unipay',
          action: 'alipay',
        },
        {
          user: '史*昆',
          price: 7.1,
          usdtLimit: '10.00 - 5,000.00',
          cnyLimit: '71.00 - 35,500.00',
          payment: 'alipay',
          action: 'alipay',
        },
        {
          user: '云*习',
          price: 7.05,
          usdtLimit: '100.00 - 10,000.00',
          cnyLimit: '705.00 - 70,500.00',
          payment: 'alipay',
          action: 'alipay',
        },
      ],
    }
  },
  created() {
    // 在Vue实例创建之后执行的代码
    console.log('Vue实例已创建')
    this.fetchDataBuy()
    this.fetchDataSell()
  },
  methods: {
    fetchDataBuy() {
      axios
        .get('https://api.ex77.vip/api/login/getData?orderType=1')
        .then((response) => {
          // 请求成功时的处理
          console.log(response.data)
          this.tableDataBuy = response.data.data
          this.tableData = response.data.data
          this.loading = false
        })
        .catch((error) => {
          // 请求失败时的处理
          console.log(error)
          this.tableData = this.tableDataBuy
        })
    },
    fetchDataSell() {
      axios
        .get('https://api.ex77.vip/api/login/getData?orderType=2')
        .then((response) => {
          // 请求成功时的处理
          console.log(response.data)
          this.tableDataSell = response.data.data
        })
        .catch((error) => {
          // 请求失败时的处理
          console.log(error)
          this.response = this.tableDataSell
        })
    },
    handleClick(tab) {
      console.log(tab.index)
      if (1 == tab.index) {
        this.tableData = this.tableDataSell
        this.formInline.type = 'sell'
      } else {
        this.tableData = this.tableDataBuy
        this.formInline.type = 'buy'
      }
    },
    onSubmit() {
      console.log('submit!')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
  },
}
</script>

<style>
.tabs {
  /* width: 500px; */
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.is-active {
  color: #ff6835 !important;
}

.el-tabs__active-bar {
  color: aliceblue;
}

.el-tabs__active-bar {
  background-color: #ff6835 !important;
}

/*去掉tabs底部的下划线*/
.el-tabs__nav-wrap::after {
  position: static !important;
}

.search div {
  /* display: inline-block; */
}

.search {
  margin-top: 50px;
}

.table {
  margin: 0 50px 20px 50px;
  display: flex;
  justify-content: center;
}
.user-index {
  height: 20px;
  width: 20px;
  background-color: #7e489d;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
.page {
  margin-top: 10px;
}

.tips {
  width: 100%;
  height: 260px;
  background-image: url(@/assets/tips.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-top: 20px;
  color: #fff;
  padding-top: 20px;
  margin: 0 auto;
}

.tip1 {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}

.tip2 {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
}
.tip3 {
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  display: inline-block;
  position: absolute;
  left: 52%;
  transform: translateX(-50%);
}

.footer {
  width: 100%;
  height: 230px;
  background: #2e2e2e;
}

.table {
  margin: 10px 80px 0 80px;
}
</style>
