<template>
  <div class="hello">
    <el-row :gutter="20">
      <el-col :xs="6" :sm="6" :md="13" :lg="13" :xl="13">
        <div class="address">
          <p class="a1">WX</p>
          <!-- <p class="a1">
            Cyber Tech LLC<br />
            1408LLC2021 1st floor<br />
            1st St Vincent Bank Ltd Building, James Street, Kingstown, St.<br />
            Vincent and the Grenadines
          </p> -->
          <p class="rights">2023 WX All Rights Reserved</p>
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3">
        <div class="about">
          <p class="gy">
            <a href="#">关于</a>
          </p>

          <p class="hz"><a href="#">与我们合作</a></p>
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3">
        <div class="service">
          <p class="fw"><a href="#">服务</a></p>
          <p class="bzzx"><a href="#">帮助中心</a></p>
          <p class="shht"><a href="#">商户后台</a></p>
          <p class="jyyht"><a href="#">交易员后台</a></p>
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3">
        <div class="term">
          <p class="tk"><a href="#">条款</a></p>
          <p class="yhxy"><a href="#">用户协议</a></p>
          <p class="yszc"><a href="#">隐私政策</a></p>
          <p class="fxqtl"><a href="#">反洗钱条例</a></p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'FooterView',
  props: {
    msg: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
  color: #fff;
  text-decoration: none;
}
.address {
  color: #fff;
  padding-left: 30px;

  text-align: start;
}

.about {
  color: #fff;
  padding-left: 30px;
  font-size: 11px;
  text-align: start;
}

.service {
  color: #fff;
  padding-left: 30px;
  font-size: 11px;
  text-align: start;
}

.term {
  color: #fff;
  padding-left: 30px;
  font-size: 11px;
  text-align: start;
}

.rights {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6835;
  line-height: 20px;
}

.hello{
  padding-top: 30px;
}
</style>
