<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-row :gutter="20">
          <el-col :xs="8" :sm="8" :md="16" :lg="18" :xl="18"
            ><div class="logo">
              <img src="./assets/logo.jpg" width="130" height="45" /></div
          ></el-col>
          <el-col :xs="16" :sm="16" :md="8" :lg="6" :xl="6">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              active-text-color="#FF6835"
              background-color="#2e2e2e"
              text-color="#ffffff"
            >
              <el-menu-item index="1">交易大厅</el-menu-item>
              <el-menu-item index="2">关于我们</el-menu-item>
              <el-menu-item index="3">注册</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
      </el-main>
      <!-- <el-footer height="230">
        <FooterView></FooterView>
      </el-footer> -->
    </el-container>
    <div>
      <!-- <p> -->
      <!--使用 router-link 组件进行导航 -->
      <!--通过传递 `to` 来指定链接 -->
      <!--`<router-link>` 将呈现一个带有正确 `href` 属性的 `<a>` 标签-->
      <!-- <router-link to="/">Go to Home</router-link>
        <router-link to="/about">Go to About</router-link> -->
      <!-- </p> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import FooterView from '@/components/FooterView.vue'
export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      activeIndex: '1',
    }
  },
  methods: {
    handleSelect(key) {
      console.log(key)
      console.log(this.activeIndex)
      switch (key) {
        case '1':
          this.$router.push(
            '/',
            () => {},
            () => {}
          )
          break
        case '2':
          this.$router.push(
            '/about',
            () => {},
            () => {}
          )
          break
        default:
          this.$router.push(
            '/register',
            () => {},
            () => {}
          )
          break
      }
    },
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
::v-deep .el-menu--horizontal>.el-menu-item {
  width: 75px;
}

::v-deep .el-menu.el-menu--horizontal{
  border: none;
}

.el-header {
  /* border-bottom: #2E2E2E solid 1px; */
}

.el-menu-item{
  width: 75px;
}
.el-footer {
  width: 100%;
  height: 230px;
  background: #2e2e2e;
  /* position: absolute;
  bottom: 0; */
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  /* margin-left: 60px; */
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
.el-tabs__active-bar {
  /* background-color: transparent !important; */
}

.el-menu--horizontal {
  /* border-bottom: none; */
}

.el-main {
  padding: 0 !important;
}

.el-header{
 /* margin-bottom: -35px; */
 background-color: #2e2e2e;
 margin: 0;
  padding: 0;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  border-bottom: none;
  text-decoration: none;
}

::v-deep .el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: none;
  text-decoration: none;
}
</style>
